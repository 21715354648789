.document-list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {

    .document-list-container .search-buttons {
        text-align: center;
    }

    
}

@media screen and (max-width: 576px) {
    .document-list-grid {
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr))!important;
    }
}

.search {
    width: 100%;
}

.list {
    flex: 1 1 auto;
    overflow-y: auto;
}

.document-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    justify-content: center;
    padding: 0.375rem;
}

.document-list-item {
    box-sizing: border-box;
    aspect-ratio: 210/297;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    text-align: left;
    padding: 0 0.3em;
    color: #333;
    border: 3px solid transparent;
    box-sizing: border-box;
}

.document-list-item.active {
    border-color: #06f;
}

.document-list-item .card-title {
    background-color: rgba(255, 255, 255, 0.75)
}

.document-list>*>.icons {
    color: #333;
}

ul.pagination {
    margin-bottom: 0;
}