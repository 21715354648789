.document-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.document-container .contents {
    flex: 1 1 auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.document-container .contents .details {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.document-container .contents .details .ocr {
    flex: 1 1 auto;
    overflow: scroll;
}

.document-container .contents .details .ocr div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.document-container .contents .details .ocr div p {
    flex: 1 1 auto;
    overflow: scroll;
}

.document-container .contents .details .links {
    min-height: 12rem;
}

.pdf-preview {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pdf-preview .document {
    flex: 1 1 auto;
    overflow: scroll;
}

.pdf-preview .react-pdf__Page__canvas {
    margin: auto;
}