.main-container {
    display: flex;
    height: 100%;
    padding-bottom: 0.50rem;
}

@media screen and (min-width: 768px) {
    .right-pane {
        aspect-ratio: 210/340;
        height: 100%;
        min-height: 25rem;
        max-height: 60rem;
    }
}

.right-pane {
    box-sizing: border-box;
}