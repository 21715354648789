.App {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.App .content {
  flex: 1 1 auto;
  overflow: scroll;
}

.App .navbar {
  flex: 0 0 auto;
}

.App .footer {
  flex: 0 1 40px;
}